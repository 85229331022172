<template>
	<div class="page">
		<div class="view">
			<div class="comView">
				<div class="comItem" style="align-items: initial;height: auto;">
					<div class="comItemName">店铺logo:</div>
					<el-upload ref="fileUpload" :http-request="getUploadImg" :multiple="false" list-type="picture-card"
						:on-remove="handleRemove" :auto-upload="true" :show-file-list="false" action="" class="cropper-upload-box">
						<div style="display: flex;flex-direction: column;align-items: center;justify-content: center">
							<img v-if="imgUrl" :src="imgUrl" class="avatar" >
							<!-- <i v-else class="el-icon-plus"></i> -->
						</div>
					</el-upload>
					<div class="imgTxt">
						<div>(店铺logo为展示打印小票)</div>
					</div>
				</div>
			</div>
			<div class="comView">
				<div class="comItem" style="align-items: initial;height: auto;">
					<div class="comItemName">二维码:</div>
					<el-upload ref="fileUpload" :http-request="getUploadImgCode" :multiple="false" list-type="picture-card"
						:on-remove="handleRemove" :auto-upload="true" :show-file-list="false" action="" class="cropper-upload-box">
						<div style="display: flex;flex-direction: column;align-items: center;justify-content: center">
							<img v-if="imgUrl2" :src="imgUrl2" class="avatar">
							<!-- <i v-else class="el-icon-plus"></i> -->
						</div>
					</el-upload>
					<div class="imgTxt">
						<div>(店铺二维码为展示打印小票的内容展示)</div>
					</div>
				</div>
			</div>
			<div class="comView">
				<div class="comItem">
					<div class="comItemName">店铺名称:</div>
					<el-input v-model="shopData.name" placeholder="请输入店铺名称" style="width: 200px;" v-if="shopData">
					</el-input>
				</div>
			</div>
			<div class="comView">
				<div class="comItem">
					<div class="comItemName">店铺电话:</div>
					<el-input type="phone" v-model="shopData.tel" placeholder="请输入店铺电话" style="width: 200px;" v-if="shopData">
					</el-input>
				</div>
			</div>
			<div class="comView">
				<div class="comItem">
					<div class="comItemName">营业时间:</div>
					<el-input v-model="shopData.businessHours" placeholder="请输入营业时间" style="width: 200px;" v-if="shopData">
					</el-input>
				</div>
			</div>
			<div class="comView">
				<div class="comItem">
					<div class="comItemName">店铺地址:</div>
					<el-input v-model="shopData.address" placeholder="请输入店铺地址" style="width: 420px;" v-if="shopData">
					</el-input>
				</div>
			</div>
		</div>
		<div class="btnView">
			<el-button type="primary" v-if="$buttonAuthority('dianpu-dianpu-submit')"  style="width: 148px;" @click="handleSubmitClick">提交</el-button>
		</div>
	</div>
</template>

<script>
import { put, remove } from '@/api/upload.js'
// import { put, remove } from '@/api/upload.js'
export default {
	data() {
		return {
			imgUrl: '', //logo
			imgUrl2: '',  //二维码
			shopData: null,  //信息
			logo: '',  //图片地址
			qrCode: '',  //二维码
			dialogVisible: false,
			fileList: []
		}
	},
	mounted() {
		this.getShopData()
	},
	methods: {
		//提交
		handleSubmitClick() {
			let params = this.shopData;
			params.logo = this.logo;
			params.qrCode = this.qrCode;
			this.$http.post("/cms/CompanyInfo/UpdateCompanyShopInfo", params).then(res => {
				if (res.code === 0) {
					this.$message({
						type: 'success',
						message: '已提交'
					});
					this.$router.go(-1);
				}
			})
		},
		//获取店铺信息
		getShopData() {
			let params = {
				shopId: this.$store.state.userInfo.nowShop.id
			}
			this.$http.get("/cms/CompanyInfo/GetShopInfo", params).then(res => {
				if (res.code === 0) {
					this.shopData = res.data;
					this.imgUrl = res.data.logo;
					this.logo = res.data.logo;
					this.imgUrl2 = res.data.qrCode;
					this.qrCode = res.data.qrCode;
				}
			})
		},
		//上传二维码
		getUploadImgCode(item) {
				// 随机命名
				var fileName = item.file.name
			var file = item.file // 
			put(fileName, file).then(result => {  // 调oss api 上传图片
				this.qrCode = result.url;
				this.imgUrl2 = result.url;
				// this.fileList.push(result.name)
			})
		},
		getUploadImg(item) {
			// 随机命名
			var fileName = item.file.name
			var file = item.file // 
			put(fileName, file).then(result => {  // 调oss api 上传图片
				this.logo = result.url;
				this.imgUrl = result.url;
				// this.fileList.push(result.name)
			})
		},
		// 删除
		handleRemove(file) {
			var fileName = file.name
			var removeName = ''
			this.fileList.forEach(function (name) {
				if (name.match(fileName)) {
					removeName = name
				}
			})
			remove(removeName).then(result => {
				if (result.res.status != '204') {
					console.log('删除失败')
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.page {
	background: #fff;
	width: calc(100% - 64px);
	height: calc(100% - 64px);
	border-radius: 20px;
	padding: 32px;
	overflow-y: scroll;
	font-size: 14px;
	display: flex;
	flex-direction: column;

	.view {
		flex: 1;

		.comView {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			font-size: 14px;

			.comItem {
				display: flex;
				align-items: center;
				height: 40px;

				.comItemName {
					text-align: right;
					margin-right: 12px;
					width: 60px;

					span {
						color: #FF3939;
					}
				}

				.imgTxt {
					display: flex;
					align-items: flex-end;

					div {
						color: #FF3939;
						margin-left: 12px;
						font-size: 12px;
					}
				}
			}

			.comTxt {
				color: #666;
				margin-left: 12px;

				span {
					color: #F77E04;
					cursor: pointer;
				}
			}
		}
	}

	.btnView {
		text-align: right;
	}
}

.page::-webkit-scrollbar {
	display: none;
}
.upload-cropper-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
}

.cropper-upload-box {
	display: flex;

	.el-upload {
		width: 120px;
		height: 120px;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 120px;
			height: 120px;
		}
	}
}
</style>
